import './App.css';
import Header from './Header';
import Invoice from './Invoice';
import { Link } from 'react-router-dom';
import {BrowserRouter,Route,Routes} from "react-router-dom";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Invoice/>}></Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
