import React from "react";

const Logo = ()=>{
    return(
        <>
        <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="326.677" height="61.391" viewBox="0 0 326.677 61.391">
            <g id="Group_10210" data-name="Group 10210" transform="translate(-59 -444.014)">
                <g id="Group_10586" data-name="Group 10586" transform="translate(305.342 444.014)">
                <path id="Path_16944" data-name="Path 16944" d="M668.815,256.719c-1.131,1.764.15,2.3.15,2.3a1.381,1.381,0,0,0,.136,1.441,4.266,4.266,0,0,0,5.109.673c2.6-1.308,4.09-3.824,5.223-4.714a3.354,3.354,0,0,1,2.162-.81s-7.906-4.489-12.782,1.107" transform="translate(-601.261 -224.994)" fill="#5ac268" fill-rule="evenodd"/>
                <path id="Path_16945" data-name="Path 16945" d="M649.581,329.785a2.809,2.809,0,0,0,1.674,2.871c1.663.812,3.486.587,4.372.815a2.168,2.168,0,0,1,1.246.786s-.889-5.794-5.56-6.085c-1.311.117-1.074.977-1.074.977a.866.866,0,0,0-.659.636" transform="translate(-584.659 -290.609)" fill="#5ac268" fill-rule="evenodd"/>
                <path id="Path_16946" data-name="Path 16946" d="M537.358,301.951c-4.594,1.47-4.069,7.481-4.069,7.481a2.252,2.252,0,0,1,1.052-1.1c.827-.451,2.7-.687,4.166-1.919a2.892,2.892,0,0,0,.97-3.284.869.869,0,0,0-.809-.471s.029-.921-1.31-.708" transform="translate(-481.915 -267.414)" fill="#5ac268" fill-rule="evenodd"/>
                <path id="Path_16947" data-name="Path 16947" d="M377.13,29.24a2.793,2.793,0,0,0,3.371-.13.915.915,0,0,0,.176-.934s.864-.27.229-1.488c-2.851-3.948-8.272-1.5-8.272-1.5a2.2,2.2,0,0,1,1.365.658c.688.649,1.513,2.381,3.131,3.392" transform="translate(-339.988 -22.251)" fill="#5ac268" fill-rule="evenodd"/>
                <path id="Path_16948" data-name="Path 16948" d="M395.846,75.479c-4.815-.144-6.258,5.708-6.258,5.708a2.218,2.218,0,0,1,1.35-.688c.927-.15,2.776.253,4.556-.419A2.877,2.877,0,0,0,397.47,77.3a.886.886,0,0,0-.617-.713s.325-.863-1.008-1.109" transform="translate(-354.967 -67.345)" fill="#5ac268" fill-rule="evenodd"/>
                <path id="Path_16949" data-name="Path 16949" d="M156.414,37.752c5.977-4.385,5.388-11.193,4.049-15.94C157.708,12.08,145.244,5,131.123,5.7c9.222,4.816,7.324,14.156,7.957,20.485.71,8.407,6.873,13.005,10.54,13.433,2.03-7.756.811-15.17-2.023-23,6.306,9.336,9.742,33.235-4.66,43.777l-.289.158a110.624,110.624,0,0,1-29.242-1.241V38.908a1.45,1.45,0,0,1,.672-1.228L131.342,26.8a1.4,1.4,0,0,1,1.495,0l1.548.975a1.408,1.408,0,0,0,2.138-1.347l0-.031c-.111-1.115-.153-2.238-.178-3.363a1.451,1.451,0,0,0-.672-1.2l-2.834-1.785a1.4,1.4,0,0,0-1.495,0L99.771,39.942a1.459,1.459,0,0,0-.46,1.989l1.474,2.421a1.406,1.406,0,0,0,1.954.467l2.86-1.8a1.42,1.42,0,0,1,2.167,1.228V58.2a135.4,135.4,0,0,1-15.44-4.426c21.963,18.013,55.979,17.24,78.157.411a112.238,112.238,0,0,1-14.069,4.181Z" transform="translate(-92.326 -5.654)" fill="#1a7279" fill-rule="evenodd"/>
                <path id="Path_16954" data-name="Path 16954" d="M382.779,348.1h-2.707a1.392,1.392,0,0,0-1.38,1.4v2.758a1.392,1.392,0,0,0,1.38,1.4h2.707a1.392,1.392,0,0,0,1.38-1.4V349.5a1.392,1.392,0,0,0-1.38-1.4" transform="translate(-345.34 -308.214)" fill="#5ac268" fill-rule="evenodd"/>
                <path id="Path_16955" data-name="Path 16955" d="M442.59,348.1h-2.707a1.392,1.392,0,0,0-1.38,1.4v2.758a1.392,1.392,0,0,0,1.38,1.4h2.707a1.392,1.392,0,0,0,1.38-1.4V349.5a1.392,1.392,0,0,0-1.38-1.4" transform="translate(-398.185 -308.214)" fill="#5ac268" fill-rule="evenodd"/>
                <path id="Path_16956" data-name="Path 16956" d="M443.97,288.685a1.392,1.392,0,0,0-1.38-1.4h-2.707a1.392,1.392,0,0,0-1.38,1.4v2.754a1.392,1.392,0,0,0,1.38,1.4h2.707a1.392,1.392,0,0,0,1.38-1.4Z" transform="translate(-398.185 -254.481)" fill="#5ac268" fill-rule="evenodd"/>
                <path id="Path_16957" data-name="Path 16957" d="M379.766,288.685a1.392,1.392,0,0,0-1.38-1.4h-2.707a1.392,1.392,0,0,0-1.38,1.4v2.754a1.392,1.392,0,0,0,1.38,1.4h2.707a1.392,1.392,0,0,0,1.38-1.4Z" transform="translate(-341.459 -254.481)" fill="#5ac268" fill-rule="evenodd"/>
            </g>
            <g id="Group_10585" data-name="Group 10585" transform="translate(59 452.644)">
                <path id="Path_16958" data-name="Path 16958" d="M44.955,700.212v-.375c.676-.116.771-.231.625-.758s-.35-1.168-.582-1.918H42.664c-.181.6-.327,1.107-.465,1.624-.233.793-.145.946.676,1.053v.375h-2.4v-.375c.705-.133.858-.223,1.243-1.382L44,691.648l.378-.116c.7,2.31,1.4,4.63,2.117,6.924.348,1.151.5,1.293,1.207,1.382v.375Zm-1.069-6.887h-.022c-.35,1.079-.691,2.195-1.019,3.247h1.993Z" transform="translate(-29.693 -656.602)" fill="#1a7279"/>
                <path id="Path_16959" data-name="Path 16959" d="M47.147,699.435H43.763v-.961l.263-.045a1.19,1.19,0,0,0,.433-.117c-.007,0,0-.032-.059-.246-.135-.459-.308-1.028-.51-1.683H42.026c-.149.5-.275.941-.395,1.388-.119.407-.107.509-.1.531s.1.072.507.124l.277.037v.971H39.284v-.956l.258-.05c.578-.109.653-.124,1-1.169l2.335-6.964.84-.258.163.541c.674,2.232,1.349,4.471,2.045,6.686.329,1.085.411,1.095.943,1.162l.278.035Zm-2.28-.635h1.139a2.491,2.491,0,0,1-.688-1.348c-.675-2.152-1.333-4.33-1.989-6.5l-.324.963h.223l.088.228,1.071,3.655h-.016l.056.176c.232.752.429,1.4.583,1.922a.933.933,0,0,1-.024.767.57.57,0,0,1-.121.139m-4.463,0h.735a.691.691,0,0,1-.135-.149.792.792,0,0,1-.1-.534,1.459,1.459,0,0,1-.5.683m2-3.64h1.137l-.551-1.879c-.163.519-.324,1.038-.483,1.549Z" transform="translate(-28.819 -655.506)" fill="#1a7279"/>
                <path id="Path_16960" data-name="Path 16960" d="M86.086,698.647c-.073.447-.3,1.7-.408,2.105H80.662v-.375c.931-.08,1.025-.17,1.025-1.312v-5.121c0-1.132-.073-1.213-.975-1.284v-.393h2.945v.393c-.858.071-.959.142-.959,1.284v5.077c0,.713.051.963.254,1.1a1.97,1.97,0,0,0,1.011.17c.575,0,.96-.08,1.207-.419a3.822,3.822,0,0,0,.589-1.3Z" transform="translate(-59.174 -657.142)" fill="#1a7279"/>
                <path id="Path_16961" data-name="Path 16961" d="M85.047,700.194H79.469v-.983l.291-.027c.735-.065.735-.065.735-1v-5.119c0-.914,0-.914-.681-.968l-.293-.025v-1H83.1v1l-.292.025c-.668.055-.668.055-.668.968v5.077c0,.531.032.78.109.827a2,2,0,0,0,.838.121c.539,0,.8-.079.951-.29a3.417,3.417,0,0,0,.54-1.2l.086-.293.909.226-.047.291c-.072.435-.3,1.713-.416,2.138Zm-.947-.636h.453c.034-.149.071-.333.109-.529q-.06.089-.119.164a1.142,1.142,0,0,1-.443.365m-3.319,0h1.21a.912.912,0,0,1-.092-.055c-.366-.236-.393-.693-.393-1.358v-5.077c0-.758.066-1.15.343-1.361H80.781c.3.221.349.623.349,1.361v5.119c0,.757-.068,1.152-.35,1.37" transform="translate(-58.299 -656.266)" fill="#1a7279"/>
                <path id="Path_16962" data-name="Path 16962" d="M120.413,700.868a2.838,2.838,0,0,1-.385-.026,1.98,1.98,0,0,1-1.833-1.2c-.37-.633-.727-1.427-1.054-2.051a.868.868,0,0,0-.945-.536h-.306v2.035c0,1.1.1,1.195.974,1.284v.375H113.92v-.375c.88-.089.982-.187.982-1.284v-5.175c0-1.116-.094-1.186-.938-1.258v-.393h2.785a2.622,2.622,0,0,1,1.767.455,2.059,2.059,0,0,1,.72,1.713,2.349,2.349,0,0,1-1.374,2.212c.189.428.632,1.284.96,1.883a10.645,10.645,0,0,0,.879,1.374,1.375,1.375,0,0,0,.763.6Zm-4.035-4.246a1.581,1.581,0,0,0,1.185-.375,2.045,2.045,0,0,0,.582-1.606c0-1.4-.749-1.918-1.556-1.918a.918.918,0,0,0-.582.125c-.073.072-.116.223-.116.562v3.212Z" transform="translate(-83.572 -657.142)" fill="#1a7279"/>
                <path id="Path_16963" data-name="Path 16963" d="M119.815,700.308h-.277a3.1,3.1,0,0,1-.427-.027,2.288,2.288,0,0,1-2.066-1.351c-.235-.4-.464-.869-.685-1.316-.129-.263-.254-.517-.376-.747-.143-.275-.226-.365-.651-.367v1.718c0,.9,0,.9.688.968l.285.027v.981h-3.58v-.981l.285-.027c.7-.072.7-.072.7-.968v-5.176c0-.886,0-.886-.647-.941l-.291-.025v-1h3.1a2.888,2.888,0,0,1,1.969.529,2.382,2.382,0,0,1,.835,1.959A2.645,2.645,0,0,1,117.4,695.9c.221.46.567,1.122.828,1.6a10.112,10.112,0,0,0,.849,1.328,1.089,1.089,0,0,0,.6.492l.267.075Zm-3.754-4.29a1.219,1.219,0,0,1,.486.554c.123.233.252.494.383.762.215.432.437.884.664,1.273a1.658,1.658,0,0,0,1.342,1.018,2.341,2.341,0,0,1-.369-.412,10.685,10.685,0,0,1-.9-1.4c-.325-.588-.776-1.462-.976-1.909l-.045-.1a1.689,1.689,0,0,1-.59.219m-2.071,3.54h1.063c-.3-.224-.355-.621-.355-1.341v-5.685a1.057,1.057,0,0,1,.209-.787l.047-.037h-.942c.268.209.332.593.332,1.333v5.176c0,.735-.071,1.125-.354,1.341m1.343-4.131h.169a1.28,1.28,0,0,0,.963-.286,1.723,1.723,0,0,0,.487-1.378c0-1.03-.44-1.6-1.239-1.6a1.024,1.024,0,0,0-.367.045c.01.008-.013.094-.013.323Zm1.3-3.667a2.112,2.112,0,0,1,.954,2,2.556,2.556,0,0,1-.407,1.52,1.976,1.976,0,0,0,.862-1.723,1.748,1.748,0,0,0-.594-1.46,1.643,1.643,0,0,0-.815-.34" transform="translate(-82.697 -656.265)" fill="#1a7279"/>
                <path id="Path_16964" data-name="Path 16964" d="M154.385,700.212v-.375c.676-.116.771-.231.625-.758s-.35-1.168-.582-1.918h-2.334c-.181.6-.327,1.107-.465,1.624-.233.793-.145.946.676,1.053v.375h-2.4v-.375c.705-.133.858-.223,1.243-1.382l2.283-6.808.378-.116c.7,2.31,1.4,4.63,2.117,6.924.348,1.151.5,1.293,1.207,1.382v.375Zm-1.069-6.887h-.022c-.35,1.079-.691,2.195-1.019,3.247h1.993Z" transform="translate(-109.971 -656.602)" fill="#1a7279"/>
                <path id="Path_16965" data-name="Path 16965" d="M156.577,699.435h-3.384v-.961l.263-.045a1.19,1.19,0,0,0,.433-.117c-.006,0,0-.032-.059-.246-.135-.459-.308-1.028-.51-1.683h-1.863c-.15.5-.275.941-.395,1.388-.119.407-.107.509-.1.531s.1.072.507.124l.277.037v.971h-3.035v-.956l.258-.05c.578-.109.653-.124,1-1.169l2.335-6.964.84-.258.163.541c.674,2.232,1.349,4.471,2.045,6.686.329,1.085.411,1.095.943,1.162l.278.035Zm-2.28-.635h1.139a2.491,2.491,0,0,1-.688-1.348c-.675-2.152-1.333-4.33-1.989-6.5l-.324.963h.222l.089.228,1.071,3.655H153.8l.056.176c.232.752.429,1.4.583,1.922a.934.934,0,0,1-.024.767.572.572,0,0,1-.122.139m-4.463,0h.735a.69.69,0,0,1-.135-.149.792.792,0,0,1-.1-.534,1.459,1.459,0,0,1-.5.683m2-3.64h1.137l-.551-1.879c-.159.506-.317,1.013-.472,1.514Z" transform="translate(-109.097 -655.506)" fill="#1a7279"/>
                <path id="Path_16966" data-name="Path 16966" d="M194.118,693.771c-.189-.839-.524-1.784-1.439-1.784a1.265,1.265,0,0,0-1.2,1.418c0,.919.5,1.366,1.389,1.892.923.553,1.883,1.169,1.883,2.606a2.4,2.4,0,0,1-2.457,2.515,2.8,2.8,0,0,1-1.091-.214,3.117,3.117,0,0,1-.538-.277c-.073-.259-.233-1.445-.3-2.079l.327-.116c.181.83.712,2.195,1.825,2.195.793,0,1.259-.6,1.259-1.534s-.582-1.392-1.411-1.927a2.959,2.959,0,0,1-1.8-2.552,2.24,2.24,0,0,1,2.276-2.382,3.88,3.88,0,0,1,1.41.312c.043.464.094,1,.189,1.856Z" transform="translate(-139.653 -656.602)" fill="#1a7279"/>
                <path id="Path_16967" data-name="Path 16967" d="M191.355,699.857a3.094,3.094,0,0,1-1.214-.238,3.019,3.019,0,0,1-.513-.256l-.107-.067-.1-.159c-.088-.313-.251-1.577-.309-2.135l-.025-.248.9-.318.077.353c.071.323.478,1.944,1.514,1.944.849,0,.941-.852.941-1.217,0-.787-.478-1.152-1.266-1.658a3.285,3.285,0,0,1-1.942-2.82,2.563,2.563,0,0,1,2.594-2.7,4.2,4.2,0,0,1,1.527.335l.181.072.019.194c.043.464.094,1,.187,1.852l.033.286-.915.2-.07-.308c-.242-1.075-.58-1.534-1.13-1.534-.651,0-.881.593-.881,1.1,0,.755.4,1.122,1.233,1.619a3.27,3.27,0,0,1,2.038,2.877,2.712,2.712,0,0,1-2.774,2.833m-1.349-1.01a2.347,2.347,0,0,0,.372.184,2.535,2.535,0,0,0,.4.129,2.411,2.411,0,0,1-.881-.976c.038.273.077.514.106.663m.409-7.207a2.157,2.157,0,0,0-.467,1.4,2.682,2.682,0,0,0,1.645,2.282c.888.571,1.563,1.092,1.563,2.2a2.248,2.248,0,0,1-.243,1.075,2.228,2.228,0,0,0,.581-1.567c0-1.2-.749-1.748-1.728-2.331a2.437,2.437,0,0,1-1.543-2.165,2.087,2.087,0,0,1,.192-.889m2.164-.581a1.788,1.788,0,0,1,.48.554c-.013-.151-.027-.288-.04-.42a4.573,4.573,0,0,0-.44-.134" transform="translate(-138.714 -655.725)" fill="#1a7279"/>
                <path id="Path_16968" data-name="Path 16968" d="M225.328,693.771c-.189-.839-.524-1.784-1.439-1.784a1.265,1.265,0,0,0-1.2,1.418c0,.919.5,1.366,1.389,1.892.923.553,1.883,1.169,1.883,2.606a2.4,2.4,0,0,1-2.457,2.515,2.8,2.8,0,0,1-1.091-.214,3.132,3.132,0,0,1-.537-.277c-.073-.259-.232-1.445-.3-2.079l.327-.116c.181.83.712,2.195,1.825,2.195.793,0,1.259-.6,1.259-1.534s-.582-1.392-1.411-1.927a2.959,2.959,0,0,1-1.8-2.552,2.24,2.24,0,0,1,2.276-2.382,3.88,3.88,0,0,1,1.41.312c.043.464.094,1,.189,1.856Z" transform="translate(-162.548 -656.602)" fill="#1a7279"/>
                <path id="Path_16969" data-name="Path 16969" d="M222.562,699.857a3.1,3.1,0,0,1-1.215-.238,2.987,2.987,0,0,1-.51-.256l-.108-.067-.1-.159c-.087-.31-.251-1.559-.31-2.135l-.025-.251.9-.313.077.35c.071.323.478,1.944,1.514,1.944.849,0,.941-.852.941-1.217,0-.787-.478-1.152-1.266-1.658a3.286,3.286,0,0,1-1.942-2.82,2.563,2.563,0,0,1,2.594-2.7,4.2,4.2,0,0,1,1.527.335l.181.072.019.194c.043.464.094,1,.187,1.852l.033.286-.915.2-.07-.308c-.242-1.075-.58-1.534-1.13-1.534-.652,0-.883.593-.883,1.1,0,.752.4,1.122,1.234,1.619a3.27,3.27,0,0,1,2.038,2.877,2.712,2.712,0,0,1-2.775,2.833m-1.349-1.01a2.391,2.391,0,0,0,.371.184,2.564,2.564,0,0,0,.406.129,2.392,2.392,0,0,1-.883-.976c.039.273.077.514.106.663m.407-7.205a2.157,2.157,0,0,0-.464,1.4,2.682,2.682,0,0,0,1.645,2.282c.888.571,1.563,1.092,1.563,2.2a2.248,2.248,0,0,1-.243,1.075,2.228,2.228,0,0,0,.581-1.567c0-1.2-.749-1.748-1.728-2.331a2.442,2.442,0,0,1-1.544-2.165,2.092,2.092,0,0,1,.191-.886m2.166-.583a1.785,1.785,0,0,1,.48.554c-.014-.151-.027-.288-.04-.42a4.576,4.576,0,0,0-.441-.134" transform="translate(-161.607 -655.725)" fill="#1a7279"/>
                <path id="Path_16970" data-name="Path 16970" d="M252.676,700.753v-.375c.887-.08.982-.161.982-1.284v-5.149c0-1.132-.094-1.213-.982-1.284v-.393h2.967v.393c-.88.071-.975.152-.975,1.284v5.149c0,1.123.094,1.2.975,1.284v.375Z" transform="translate(-185.363 -657.142)" fill="#1a7279"/>
                <path id="Path_16971" data-name="Path 16971" d="M255.085,700.194h-3.6v-.983l.288-.025c.694-.065.694-.065.694-.968v-5.149c0-.914,0-.914-.69-.968l-.292-.025v-1h3.6v1l-.292.025c-.684.055-.684.055-.684.968v5.149c0,.9,0,.9.688.968l.288.025Zm-2.321-.636h1.044c-.269-.216-.334-.6-.334-1.341v-5.149c0-.758.067-1.15.346-1.361h-1.069c.281.211.348.6.348,1.361v5.149c0,.737-.064,1.125-.335,1.341" transform="translate(-184.487 -656.266)" fill="#1a7279"/>
                <path id="Path_16972" data-name="Path 16972" d="M301.809,700.753v-.375c.888-.08.982-.161.982-1.284v-5.149c0-1.132-.094-1.213-.982-1.284v-.393h2.967v.393c-.88.071-.975.152-.975,1.284v5.149c0,1.123.094,1.2.975,1.284v.375Z" transform="translate(-221.407 -657.142)" fill="#1a7279"/>
                <path id="Path_16973" data-name="Path 16973" d="M304.221,700.194h-3.6v-.983l.288-.025c.693-.065.693-.065.693-.968v-5.149c0-.914,0-.914-.689-.968l-.292-.025v-1h3.6v1l-.292.025c-.683.055-.683.055-.683.968v5.149c0,.9,0,.9.687.968l.288.025Zm-2.321-.636h1.045c-.27-.216-.334-.6-.334-1.341v-5.149c0-.758.067-1.15.345-1.361h-1.069c.281.211.348.6.348,1.361v5.149c0,.737-.064,1.125-.335,1.341" transform="translate(-220.534 -656.266)" fill="#1a7279"/>
                <path id="Path_16974" data-name="Path 16974" d="M334.709,692.661c-.6.035-.865.187-.916.785-.036.42-.066,1.009-.066,2.222v5.219h-.348l-4.778-6.8h-.014l-.007,3.185c0,1.2.043,1.821.073,2.2.051.678.3.838,1.047.909v.375h-2.6v-.375c.625-.054.9-.223.952-.874a20.623,20.623,0,0,0,.087-2.231v-2.8c0-.955-.036-1.026-.233-1.338a1.1,1.1,0,0,0-.967-.473v-.393h1.672l4.632,6.442h.029v-3.043c0-1.214-.043-1.8-.073-2.177-.05-.616-.3-.786-1.083-.83v-.393h2.6Z" transform="translate(-239.841 -657.142)" fill="#1a7279"/>
                <path id="Path_16975" data-name="Path 16975" d="M333.17,700.325h-.832l-4.311-6.135-.005,2.2c0,1.117.036,1.723.072,2.17.035.467.094.556.761.621l.287.027v.981H325.9v-.983l.291-.027c.521-.045.627-.136.663-.581a20.483,20.483,0,0,0,.086-2.207v-2.8c0-.879-.021-.911-.184-1.167-.144-.206-.278-.291-.725-.328l-.291-.027v-1H327.9l4.18,5.815v-2.1c0-1.244-.047-1.835-.072-2.152-.031-.385-.072-.5-.784-.539l-.3-.018v-1.01h3.231v1.008l-.3.02c-.526.032-.587.121-.62.494s-.064.929-.064,2.2Zm-6.084-.767h.8a1.063,1.063,0,0,1-.411-.782,1.1,1.1,0,0,1-.392.782m.477-6.667h.314l.109.134,4.548,6.473V698.15h-.3l-.124-.132-4.536-6.311h-.647a1.412,1.412,0,0,1,.364.365,1.438,1.438,0,0,1,.274.819m4.655-1.184a.992.992,0,0,1,.4.705,1.022,1.022,0,0,1,.369-.705Z" transform="translate(-238.966 -656.265)" fill="#1a7279"/>
                <path id="Path_16976" data-name="Path 16976" d="M373.351,692.268a3.97,3.97,0,0,1,3.127,1.115,4.287,4.287,0,0,1,.931,2.81,4.507,4.507,0,0,1-1.367,3.48,4.8,4.8,0,0,1-3.294,1.079h-2.7v-.375c.931-.08,1.025-.161,1.025-1.275v-5.176c0-1.124-.094-1.177-.931-1.258v-.4Zm-1.265,6.718c0,1.035.371,1.277,1.171,1.277,2.029,0,3-1.811,3-3.864a3.756,3.756,0,0,0-1.229-3.068,2.969,2.969,0,0,0-1.934-.606,1.544,1.544,0,0,0-.872.177c-.1.089-.138.25-.138.759Z" transform="translate(-271.464 -657.142)" fill="#1a7279"/>
                <path id="Path_16977" data-name="Path 16977" d="M371.875,700.193h-3.023v-.983l.291-.027c.734-.065.734-.065.734-.958v-5.174c0-.881,0-.881-.642-.943l-.288-.027v-1.008h3.531a4.283,4.283,0,0,1,3.367,1.224,4.632,4.632,0,0,1,1.009,3.019,4.829,4.829,0,0,1-1.472,3.717,5.114,5.114,0,0,1-3.505,1.162m-1.707-.635h1.4c-.467-.214-.669-.675-.669-1.45v-5.326c0-.541.036-.814.248-1a.809.809,0,0,1,.119-.075h-1.1c.284.206.351.589.351,1.343v5.174c0,.732-.067,1.12-.344,1.333m2.056-7.394a1.31,1.31,0,0,0-.681.112c.012,0-.013.109-.013.506v5.326c0,.871.247.958.853.958,1.982,0,2.686-1.909,2.686-3.545a3.44,3.44,0,0,0-1.105-2.818,2.67,2.67,0,0,0-1.74-.539m1.548-.325a2.773,2.773,0,0,1,.587.367,4.043,4.043,0,0,1,1.346,3.315,4.476,4.476,0,0,1-1.307,3.441,3.5,3.5,0,0,0,.56-.4,4.212,4.212,0,0,0,1.26-3.242,3.988,3.988,0,0,0-.854-2.6,2.919,2.919,0,0,0-1.591-.874" transform="translate(-270.59 -656.265)" fill="#1a7279"/>
                <path id="Path_16978" data-name="Path 16978" d="M420.023,692.661c-.632.044-.908.24-.952.776-.029.366-.066.893-.066,2.07v1.026a5.485,5.485,0,0,1-.836,3.479,2.513,2.513,0,0,1-2,.919,2.541,2.541,0,0,1-1.789-.634,4.041,4.041,0,0,1-.989-3.167v-3.114c0-1.2-.094-1.267-.952-1.356v-.393h2.915v.393c-.851.063-.945.142-.945,1.356v2.846c0,2.151.734,3.328,2.057,3.328,1.541,0,2.072-1.517,2.072-3.685v-1a15.247,15.247,0,0,0-.087-2.105c-.058-.5-.305-.678-1.055-.741v-.393h2.625Z" transform="translate(-302.566 -657.142)" fill="#1a7279"/>
                <path id="Path_16979" data-name="Path 16979" d="M415.295,700.372a2.822,2.822,0,0,1-2.006-.722,4.328,4.328,0,0,1-1.09-3.4v-3.116c0-.971,0-.971-.668-1.04l-.284-.03v-1h3.55v1.006l-.294.022c-.65.05-.65.05-.65,1.038v2.848c0,1.124.226,3.009,1.739,3.009.435,0,1.755,0,1.755-3.366v-1a14.956,14.956,0,0,0-.083-2.061c-.031-.266-.048-.41-.769-.469l-.29-.027v-1h3.261v1.006l-.3.022c-.564.04-.633.179-.658.484-.034.412-.065.946-.065,2.046v1.025a5.657,5.657,0,0,1-.9,3.674,2.811,2.811,0,0,1-2.248,1.043m-2.819-8.665c.294.216.359.618.359,1.43v3.116a3.777,3.777,0,0,0,.885,2.93,2.208,2.208,0,0,0,1.575.554,2.389,2.389,0,0,0,.987-.206,2.261,2.261,0,0,1-.69.1c-1.509,0-2.375-1.328-2.375-3.645v-2.848c0-.777.046-1.2.345-1.43Zm4.988,0a.969.969,0,0,1,.425.74,1.01,1.01,0,0,1,.406-.74Z" transform="translate(-301.691 -656.265)" fill="#1a7279"/>
                <path id="Path_16980" data-name="Path 16980" d="M458.7,693.771c-.189-.839-.524-1.784-1.439-1.784a1.265,1.265,0,0,0-1.2,1.418c0,.919.5,1.366,1.389,1.892.923.553,1.883,1.169,1.883,2.606a2.4,2.4,0,0,1-2.457,2.515,2.8,2.8,0,0,1-1.091-.214,3.135,3.135,0,0,1-.537-.277c-.073-.259-.232-1.445-.3-2.079l.327-.116c.181.83.712,2.195,1.825,2.195.793,0,1.259-.6,1.259-1.534s-.582-1.392-1.411-1.927a2.959,2.959,0,0,1-1.8-2.552,2.24,2.24,0,0,1,2.276-2.382,3.88,3.88,0,0,1,1.41.312c.043.464.094,1,.189,1.856Z" transform="translate(-333.748 -656.602)" fill="#1a7279"/>
                <path id="Path_16981" data-name="Path 16981" d="M455.929,699.857a3.1,3.1,0,0,1-1.215-.238,2.983,2.983,0,0,1-.51-.256l-.108-.067-.1-.159c-.087-.31-.251-1.559-.31-2.135l-.025-.251.9-.313.077.35c.071.323.478,1.944,1.514,1.944.849,0,.941-.852.941-1.217,0-.787-.478-1.152-1.266-1.658a3.286,3.286,0,0,1-1.941-2.82,2.563,2.563,0,0,1,2.594-2.7,4.2,4.2,0,0,1,1.527.335l.181.072.019.194c.043.464.094,1,.187,1.852l.033.286-.915.2-.07-.308c-.242-1.075-.58-1.534-1.13-1.534-.652,0-.882.593-.882,1.1,0,.752.4,1.122,1.234,1.619a3.27,3.27,0,0,1,2.038,2.877,2.712,2.712,0,0,1-2.775,2.833m-1.349-1.01a2.39,2.39,0,0,0,.371.184,2.564,2.564,0,0,0,.406.129,2.391,2.391,0,0,1-.883-.976c.039.273.077.514.105.663m.407-7.205a2.156,2.156,0,0,0-.464,1.4,2.682,2.682,0,0,0,1.645,2.282c.888.571,1.563,1.092,1.563,2.2a2.249,2.249,0,0,1-.243,1.075,2.228,2.228,0,0,0,.581-1.567c0-1.2-.749-1.748-1.728-2.331a2.441,2.441,0,0,1-1.544-2.165,2.092,2.092,0,0,1,.191-.886m2.166-.583a1.786,1.786,0,0,1,.48.554c-.014-.151-.027-.288-.04-.42a4.584,4.584,0,0,0-.441-.134" transform="translate(-332.806 -655.725)" fill="#1a7279"/>
                <path id="Path_16982" data-name="Path 16982" d="M491.571,693.463a3.565,3.565,0,0,0-.371-1.222c-.181-.3-.371-.4-1.2-.4h-.741V698.2c0,1.035.094,1.186,1.127,1.266v.375h-3.221v-.375c1-.081,1.091-.223,1.091-1.266v-6.362h-.632c-.945,0-1.171.124-1.316.4a4.567,4.567,0,0,0-.371,1.222h-.327c.051-.848.1-1.713.123-2.436h.233c.161.331.3.331.618.331h4.406a.563.563,0,0,0,.568-.331h.233c0,.6.058,1.606.109,2.392Z" transform="translate(-356.243 -656.232)" fill="#1a7279"/>
                <path id="Path_16983" data-name="Path 16983" d="M489.773,699.285h-3.857V698.3l.292-.025c.8-.065.8-.065.8-.948V691.28h-.314c-.913,0-.978.124-1.037.234a4.642,4.642,0,0,0-.341,1.142l-.057.248h-.917l.02-.338c.051-.842.1-1.706.123-2.425l.008-.308h.741l.087.179c.073.152.073.152.332.152h4.407c.164,0,.177,0,.305-.189l.094-.142h.719v.318c0,.638.068,1.76.108,2.374l.02.3-.924.119-.053-.3a3.391,3.391,0,0,0-.328-1.11c-.1-.166-.152-.253-.931-.253h-.423v6.046c0,.884,0,.884.834.948l.293.025Zm-2.514-.636H488.4c-.329-.226-.393-.623-.393-1.323V690.8h-.367v6.527c0,.7-.063,1.1-.384,1.323m2.87-7.853a1.014,1.014,0,0,1,.413.4c.011.017.021.037.032.057-.01-.2-.017-.392-.024-.574a.864.864,0,0,1-.422.112m-5.03-.094c-.008.179-.016.365-.026.553a.644.644,0,0,1,.032-.057.838.838,0,0,1,.42-.4.9.9,0,0,1-.426-.1" transform="translate(-355.313 -655.356)" fill="#1a7279"/>
                <path id="Path_16984" data-name="Path 16984" d="M529.911,700.868a2.835,2.835,0,0,1-.385-.026,1.98,1.98,0,0,1-1.833-1.2c-.37-.633-.727-1.427-1.054-2.051a.868.868,0,0,0-.945-.536h-.306v2.035c0,1.1.1,1.195.974,1.284v.375h-2.945v-.375c.88-.089.982-.187.982-1.284v-5.175c0-1.116-.094-1.186-.938-1.258v-.393h2.785a2.622,2.622,0,0,1,1.767.455,2.059,2.059,0,0,1,.72,1.713,2.349,2.349,0,0,1-1.374,2.212c.189.428.633,1.284.96,1.883a10.648,10.648,0,0,0,.879,1.374,1.375,1.375,0,0,0,.763.6Zm-4.035-4.246a1.581,1.581,0,0,0,1.185-.375,2.044,2.044,0,0,0,.582-1.606c0-1.4-.749-1.918-1.556-1.918a.918.918,0,0,0-.582.125c-.073.072-.116.223-.116.562v3.212Z" transform="translate(-383.98 -657.142)" fill="#1a7279"/>
                <path id="Path_16985" data-name="Path 16985" d="M529.313,700.308h-.277a3.147,3.147,0,0,1-.428-.027,2.286,2.286,0,0,1-2.064-1.351c-.235-.4-.464-.869-.685-1.316-.129-.263-.254-.517-.376-.747-.143-.275-.226-.365-.651-.367v1.718c0,.9,0,.9.688.968l.285.027v.981h-3.58v-.981l.285-.027c.7-.072.7-.072.7-.968v-5.176c0-.886,0-.886-.647-.941l-.291-.025v-1h3.1a2.881,2.881,0,0,1,1.968.529,2.379,2.379,0,0,1,.837,1.959,2.645,2.645,0,0,1-1.281,2.344c.221.46.567,1.122.828,1.6a10.118,10.118,0,0,0,.849,1.328,1.089,1.089,0,0,0,.6.492l.267.075Zm-3.754-4.29a1.227,1.227,0,0,1,.486.554c.123.233.252.494.383.762.215.432.437.884.664,1.273a1.658,1.658,0,0,0,1.342,1.018,2.347,2.347,0,0,1-.369-.412,10.677,10.677,0,0,1-.9-1.4c-.325-.588-.776-1.462-.976-1.909l-.045-.1a1.688,1.688,0,0,1-.589.219m-2.071,3.54h1.063c-.3-.224-.355-.621-.355-1.341v-5.685a1.057,1.057,0,0,1,.208-.787l.047-.037h-.942c.268.209.332.593.332,1.333v5.176c0,.735-.07,1.125-.354,1.341m1.343-4.131H525a1.287,1.287,0,0,0,.965-.286,1.727,1.727,0,0,0,.485-1.378c0-1.03-.44-1.6-1.239-1.6a1.025,1.025,0,0,0-.367.045c.01.008-.012.094-.012.323Zm1.3-3.667a2.112,2.112,0,0,1,.954,2,2.554,2.554,0,0,1-.407,1.52,1.976,1.976,0,0,0,.862-1.723,1.749,1.749,0,0,0-.595-1.46,1.644,1.644,0,0,0-.814-.34" transform="translate(-383.105 -656.265)" fill="#1a7279"/>
                <path id="Path_16986" data-name="Path 16986" d="M560.823,700.753v-.375c.888-.08.982-.161.982-1.284v-5.149c0-1.132-.094-1.213-.982-1.284v-.393h2.968v.393c-.881.071-.975.152-.975,1.284v5.149c0,1.123.094,1.2.975,1.284v.375Z" transform="translate(-411.42 -657.142)" fill="#1a7279"/>
                <path id="Path_16987" data-name="Path 16987" d="M563.233,700.194h-3.6v-.983l.288-.025c.694-.065.694-.065.694-.968v-5.149c0-.914,0-.914-.69-.968l-.292-.025v-1h3.6v1l-.292.025c-.684.055-.684.055-.684.968v5.149c0,.9,0,.9.688.968l.288.025Zm-2.321-.636h1.044c-.269-.216-.334-.6-.334-1.341v-5.149c0-.758.067-1.15.346-1.361H560.9c.281.211.348.6.348,1.361v5.149c0,.737-.064,1.125-.335,1.341" transform="translate(-410.544 -656.266)" fill="#1a7279"/>
                <path id="Path_16988" data-name="Path 16988" d="M590.483,700.212v-.375c.676-.116.771-.231.625-.758s-.349-1.168-.582-1.918h-2.334c-.182.6-.327,1.107-.465,1.624-.233.793-.146.946.676,1.053v.375H586v-.375c.705-.133.858-.223,1.243-1.382l2.283-6.808.378-.116c.7,2.31,1.4,4.63,2.117,6.924.349,1.151.5,1.293,1.207,1.382v.375Zm-1.069-6.887h-.022c-.349,1.079-.691,2.195-1.019,3.247h1.993Z" transform="translate(-429.893 -656.602)" fill="#1a7279"/>
                <path id="Path_16989" data-name="Path 16989" d="M592.674,699.435H589.29v-.961l.263-.045a1.189,1.189,0,0,0,.433-.117,2.443,2.443,0,0,1-.059-.246c-.135-.459-.307-1.028-.51-1.683h-1.863c-.149.5-.274.941-.395,1.388-.119.407-.106.509-.094.531s.1.072.505.124l.277.037v.971h-3.035v-.956l.258-.05c.578-.109.653-.124,1-1.169l2.335-6.964.838-.258.313,1.033c.626,2.068,1.251,4.141,1.9,6.194.329,1.085.411,1.095.943,1.162l.278.035Zm-2.279-.635h1.139a2.493,2.493,0,0,1-.688-1.348c-.644-2.051-1.271-4.126-1.9-6.2l-.092-.3-.323.961h.222l.088.228,1.072,3.655H589.9l.055.176c.235.752.431,1.4.585,1.922a.934.934,0,0,1-.024.767.584.584,0,0,1-.12.139m-4.464,0h.736a.652.652,0,0,1-.135-.149.79.79,0,0,1-.1-.534,1.469,1.469,0,0,1-.5.683m2-3.64h1.137l-.551-1.879c-.162.519-.324,1.038-.483,1.549Z" transform="translate(-429.018 -655.506)" fill="#1a7279"/>
                <path id="Path_16990" data-name="Path 16990" d="M632.327,698.647c-.073.447-.3,1.7-.408,2.105H626.9v-.375c.931-.08,1.025-.17,1.025-1.312v-5.121c0-1.132-.073-1.213-.975-1.284v-.393H629.9v.393c-.858.071-.959.142-.959,1.284v5.077c0,.713.051.963.254,1.1a1.969,1.969,0,0,0,1.011.17c.575,0,.96-.08,1.207-.419a3.825,3.825,0,0,0,.59-1.3Z" transform="translate(-459.897 -657.142)" fill="#1a7279"/>
                <path id="Path_16991" data-name="Path 16991" d="M631.288,700.194H625.71v-.983l.291-.027c.735-.065.735-.065.735-1v-5.119c0-.914,0-.914-.681-.968l-.293-.025v-1h3.58v1l-.292.025c-.668.055-.668.055-.668.968v5.077c0,.531.032.78.109.827a2,2,0,0,0,.838.121c.539,0,.8-.079.951-.29a3.419,3.419,0,0,0,.54-1.2l.086-.293.909.226-.047.291c-.072.435-.3,1.713-.416,2.138Zm-.948-.636h.453c.034-.149.071-.333.109-.529q-.06.089-.119.164a1.142,1.142,0,0,1-.443.365m-3.319,0h1.21a.925.925,0,0,1-.092-.055c-.366-.236-.393-.693-.393-1.358v-5.077c0-.758.066-1.15.343-1.361h-1.066c.3.221.349.623.349,1.361v5.119c0,.757-.068,1.152-.35,1.37" transform="translate(-459.022 -656.266)" fill="#1a7279"/>
                <path id="Path_16992" data-name="Path 16992" d="M678.013,693.463a3.567,3.567,0,0,0-.371-1.222c-.181-.3-.371-.4-1.2-.4H675.7V698.2c0,1.035.094,1.186,1.127,1.266v.375h-3.221v-.375c1-.081,1.091-.223,1.091-1.266v-6.362h-.633c-.945,0-1.171.124-1.316.4a4.55,4.55,0,0,0-.371,1.222h-.327c.05-.848.1-1.713.123-2.436h.233c.16.331.3.331.618.331h4.406a.564.564,0,0,0,.568-.331h.233c0,.6.057,1.606.109,2.392Z" transform="translate(-493.018 -656.232)" fill="#1a7279"/>
                <path id="Path_16993" data-name="Path 16993" d="M676.22,699.285h-3.857V698.3l.292-.025c.8-.065.8-.065.8-.948V691.28h-.315c-.914,0-.977.124-1.034.231a4.64,4.64,0,0,0-.343,1.145l-.057.248h-.916l.027-.477c.047-.8.094-1.606.114-2.286l.008-.308h.74l.087.179c.073.152.073.152.333.152h4.407c.164,0,.176,0,.3-.189l.094-.142h.721v.318c0,.474.036,1.271.107,2.374l.02.3-.923.119-.053-.3a3.394,3.394,0,0,0-.328-1.11c-.1-.166-.152-.253-.931-.253h-.423v6.046c0,.884,0,.884.834.948l.293.025Zm-2.514-.636h1.144c-.329-.226-.393-.623-.393-1.323V690.8h-.367v6.527c0,.7-.063,1.1-.384,1.323m2.87-7.853a1.016,1.016,0,0,1,.413.4c.011.017.021.037.032.055-.01-.208-.017-.4-.024-.571a.864.864,0,0,1-.422.112m-5.03-.094c-.009.181-.017.365-.027.553.011-.02.022-.04.033-.057a.833.833,0,0,1,.418-.4.9.9,0,0,1-.425-.1" transform="translate(-492.092 -655.356)" fill="#1a7279"/>
                <path id="Path_16994" data-name="Path 16994" d="M716.357,700.868a2.846,2.846,0,0,1-.386-.026,1.98,1.98,0,0,1-1.832-1.2c-.37-.633-.727-1.427-1.054-2.051a.868.868,0,0,0-.945-.536h-.305v2.035c0,1.1.1,1.195.974,1.284v.375h-2.945v-.375c.88-.089.982-.187.982-1.284v-5.175c0-1.116-.094-1.186-.938-1.258v-.393h2.785a2.622,2.622,0,0,1,1.767.455,2.059,2.059,0,0,1,.72,1.713,2.349,2.349,0,0,1-1.374,2.212c.189.428.632,1.284.96,1.883a10.645,10.645,0,0,0,.879,1.374,1.374,1.374,0,0,0,.763.6Zm-4.035-4.246a1.581,1.581,0,0,0,1.185-.375,2.045,2.045,0,0,0,.582-1.606c0-1.4-.749-1.918-1.556-1.918a.918.918,0,0,0-.582.125c-.073.072-.116.223-.116.562v3.212Z" transform="translate(-520.757 -657.142)" fill="#1a7279"/>
                <path id="Path_16995" data-name="Path 16995" d="M715.759,700.308h-.276a3.143,3.143,0,0,1-.427-.027,2.284,2.284,0,0,1-2.064-1.351c-.24-.412-.471-.879-.694-1.333-.125-.256-.248-.5-.367-.732-.144-.273-.227-.362-.652-.365v1.718c0,.9,0,.9.689.968l.285.027v.981h-3.58v-.981l.285-.027c.7-.072.7-.072.7-.968v-5.176c0-.886,0-.886-.648-.941l-.29-.025v-1h3.1a2.882,2.882,0,0,1,1.968.529,2.38,2.38,0,0,1,.837,1.959,2.643,2.643,0,0,1-1.282,2.344c.218.452.565,1.117.829,1.6a10.13,10.13,0,0,0,.849,1.326,1.088,1.088,0,0,0,.6.492l.268.075Zm-3.753-4.29a1.243,1.243,0,0,1,.488.551c.12.233.246.487.375.747.216.44.441.9.672,1.291a1.657,1.657,0,0,0,1.342,1.018,2.4,2.4,0,0,1-.369-.412,10.742,10.742,0,0,1-.9-1.4c-.325-.594-.776-1.46-.976-1.909l-.044-.1a1.693,1.693,0,0,1-.59.219m-2.069,3.54H711c-.3-.224-.355-.621-.355-1.341v-5.685a1.031,1.031,0,0,1,.212-.787l.043-.037h-.941c.268.209.333.593.333,1.333v5.176c0,.735-.071,1.125-.354,1.341m1.342-4.131h.17a1.278,1.278,0,0,0,.962-.286,1.72,1.72,0,0,0,.487-1.378c0-1.03-.44-1.6-1.238-1.6a1,1,0,0,0-.367.045c.008.008-.014.094-.014.323Zm1.3-3.667a2.111,2.111,0,0,1,.953,2,2.565,2.565,0,0,1-.406,1.52,1.979,1.979,0,0,0,.862-1.723,1.749,1.749,0,0,0-.595-1.46,1.645,1.645,0,0,0-.814-.34" transform="translate(-519.883 -656.265)" fill="#1a7279"/>
                <path id="Path_16996" data-name="Path 16996" d="M752.627,698.638c-.073.49-.284,1.7-.378,2.114h-5.337v-.375c.982-.09,1.076-.214,1.076-1.284v-5.113c0-1.2-.094-1.231-.938-1.32v-.393h4.929c.015.339.058,1.2.1,1.936l-.335.054a4.108,4.108,0,0,0-.356-1.133c-.16-.285-.458-.375-1.208-.375h-.807c-.357,0-.378.036-.378.428V696.1h1c.974,0,1.047-.107,1.185-.99h.327v2.561h-.327c-.152-.954-.233-1.026-1.185-1.026h-1v2.4c0,.616.059.91.262,1.07a2.194,2.194,0,0,0,1.113.152c.792,0,1.119-.089,1.366-.429a4.859,4.859,0,0,0,.552-1.258Z" transform="translate(-547.935 -657.142)" fill="#1a7279"/>
                <path id="Path_16997" data-name="Path 16997" d="M751.628,700.193h-5.91v-.983l.289-.025c.787-.072.787-.072.787-.968V693.1c0-.936,0-.936-.653-1.006l-.284-.03v-1h5.549l.015.3c.016.34.06,1.2.1,1.932l.016.288-.925.149-.057-.305a3.8,3.8,0,0,0-.329-1.053c-.055-.1-.173-.2-.921-.2h-.867c0,.042,0,.087,0,.112v2.607h.679c.757,0,.757,0,.87-.72l.042-.268h.917v3.2h-.916l-.043-.268c-.122-.757-.122-.757-.87-.757h-.679v2.081c0,.5.042.742.14.819a2.472,2.472,0,0,0,.918.087c.767,0,.956-.087,1.108-.3a4.55,4.55,0,0,0,.505-1.164l.079-.266.92.149-.046.31c-.074.509-.288,1.725-.381,2.138Zm-1.008-.635h.5c.031-.159.068-.358.105-.564-.032.055-.063.1-.1.151a1.2,1.2,0,0,1-.505.412m-3.571,0H748.3a.623.623,0,0,1-.1-.062c-.331-.258-.392-.663-.392-1.326V692.3a.815.815,0,0,1,.153-.593h-.877c.287.213.35.606.35,1.4v5.114c0,.72-.06,1.117-.381,1.341m2.742-4.059a.8.8,0,0,1,.522.286v-.564a.817.817,0,0,1-.522.278m.593-3.791a.879.879,0,0,1,.408.385l.031.064c-.009-.167-.017-.318-.024-.449Z" transform="translate(-547.06 -656.265)" fill="#1a7279"/>
                <path id="Path_16998" data-name="Path 16998" d="M787.685,698.638c-.073.49-.284,1.7-.378,2.114H781.97v-.375c.982-.09,1.076-.214,1.076-1.284v-5.113c0-1.2-.094-1.231-.938-1.32v-.393h4.929c.015.339.058,1.2.1,1.936l-.335.054a4.106,4.106,0,0,0-.356-1.133c-.16-.285-.458-.375-1.208-.375h-.807c-.357,0-.378.036-.378.428V696.1h1c.974,0,1.047-.107,1.185-.99h.327v2.561h-.327c-.153-.954-.233-1.026-1.185-1.026h-1v2.4c0,.616.059.91.262,1.07a2.2,2.2,0,0,0,1.113.152c.792,0,1.119-.089,1.366-.429a4.854,4.854,0,0,0,.553-1.258Z" transform="translate(-573.654 -657.142)" fill="#1a7279"/>
                <path id="Path_16999" data-name="Path 16999" d="M786.688,700.193h-5.909v-.983l.289-.025c.787-.072.787-.072.787-.968V693.1c0-.936,0-.936-.654-1.006l-.284-.03v-1h5.551l.014.3c.015.34.058,1.2.1,1.932l.018.288-.926.149-.057-.305a3.739,3.739,0,0,0-.33-1.053c-.053-.094-.172-.2-.921-.2H783.5c0,.042,0,.087,0,.112v2.607h.678c.758,0,.758,0,.871-.72l.042-.268h.916v3.2h-.915l-.043-.268c-.122-.757-.122-.757-.871-.757H783.5v2.081c0,.5.042.742.139.819a2.473,2.473,0,0,0,.918.087c.768,0,.957-.087,1.11-.3a4.671,4.671,0,0,0,.505-1.164l.081-.266.919.149-.046.31c-.072.484-.284,1.7-.382,2.138Zm-1.007-.635h.494c.031-.159.068-.355.106-.564-.031.055-.063.1-.094.149a1.194,1.194,0,0,1-.505.415m-3.571,0h1.245a.612.612,0,0,1-.1-.062c-.33-.258-.391-.665-.391-1.326V692.3a.815.815,0,0,1,.153-.593h-.878c.287.213.35.606.35,1.4v5.114c0,.72-.059,1.117-.381,1.341m2.741-4.059a.8.8,0,0,1,.523.286v-.564a.817.817,0,0,1-.523.278m.593-3.791a.886.886,0,0,1,.408.382c.011.022.021.042.031.065-.009-.164-.016-.315-.022-.447Z" transform="translate(-572.78 -656.265)" fill="#1a7279"/>
                <path id="Path_17000" data-name="Path 17000" d="M821.245,693.771c-.189-.839-.524-1.784-1.439-1.784a1.265,1.265,0,0,0-1.2,1.418c0,.919.5,1.366,1.389,1.892.924.553,1.883,1.169,1.883,2.606a2.4,2.4,0,0,1-2.457,2.515,2.8,2.8,0,0,1-1.091-.214,3.107,3.107,0,0,1-.538-.277c-.073-.259-.233-1.445-.3-2.079l.327-.116c.181.83.712,2.195,1.824,2.195.793,0,1.259-.6,1.259-1.534s-.582-1.392-1.411-1.927a2.959,2.959,0,0,1-1.8-2.552,2.24,2.24,0,0,1,2.276-2.382,3.879,3.879,0,0,1,1.41.312c.043.464.094,1,.189,1.856Z" transform="translate(-599.714 -656.602)" fill="#1a7279"/>
                <path id="Path_17001" data-name="Path 17001" d="M818.48,699.857a3.087,3.087,0,0,1-1.214-.238,3.342,3.342,0,0,1-.508-.253l-.114-.067-.1-.164c-.085-.308-.25-1.574-.308-2.133l-.025-.248.9-.315.077.347c.072.325.48,1.947,1.515,1.947.849,0,.941-.852.941-1.217,0-.787-.478-1.152-1.266-1.658a3.285,3.285,0,0,1-1.94-2.82,2.563,2.563,0,0,1,2.593-2.7,4.2,4.2,0,0,1,1.528.335l.182.072.017.194c.042.464.095,1,.188,1.852l.032.286-.916.193-.07-.305c-.242-1.075-.58-1.534-1.128-1.534-.652,0-.883.593-.883,1.1,0,.752.4,1.122,1.234,1.619a3.269,3.269,0,0,1,2.039,2.877,2.713,2.713,0,0,1-2.776,2.833m-1.348-1.01a2.611,2.611,0,0,0,.372.184,2.557,2.557,0,0,0,.4.129,2.409,2.409,0,0,1-.884-.978c.04.276.077.516.107.666m.407-7.205a2.157,2.157,0,0,0-.464,1.4,2.681,2.681,0,0,0,1.644,2.282c.888.571,1.563,1.092,1.563,2.2a2.259,2.259,0,0,1-.245,1.078,2.234,2.234,0,0,0,.583-1.569c0-1.2-.749-1.748-1.728-2.331a2.441,2.441,0,0,1-1.544-2.165,2.1,2.1,0,0,1,.191-.886m2.165-.583a1.789,1.789,0,0,1,.482.554c-.015-.149-.027-.288-.04-.42a4.508,4.508,0,0,0-.442-.134" transform="translate(-598.774 -655.725)" fill="#1a7279"/>
                <path id="Path_17002" data-name="Path 17002" d="M11.5,590.317c-1.726-.025-3.147-.33-3.147-2.639a3.865,3.865,0,0,1-3.68,2.639C2,590.317,0,587.576,0,584.429c0-2.918,2-5.786,5.2-5.786A5.212,5.212,0,0,1,8.2,579.658c.279.178.432.279.432.279a1.9,1.9,0,0,0,.279-.634c.051-.1,0-.2.178-.381h1.269a8.271,8.271,0,0,0-.178,1.853v6.573c0,.685.127,1.929,1.32,2.03h2.843v.939H11.5ZM2.31,584.582c0,3.731,1.624,4.873,2.69,4.873A2.994,2.994,0,0,0,7.74,587.17,11.154,11.154,0,0,0,8.02,584.1c0-2.157-.279-4.568-2.817-4.568-1.98,0-2.893,2.436-2.893,5.05m2.766-9.06a1.284,1.284,0,0,1-1.294,1.295,1.294,1.294,0,0,1,0-2.589,1.284,1.284,0,0,1,1.294,1.294m3.654,0a1.294,1.294,0,1,1-1.294-1.294,1.268,1.268,0,0,1,1.294,1.294" transform="translate(0 -570.547)" fill="#1a7279"/>
                <path id="Path_17003" data-name="Path 17003" d="M52.221,607.06a1.284,1.284,0,0,1-1.294,1.294,1.253,1.253,0,0,1-1.294-1.294,1.233,1.233,0,0,1,1.294-1.269,1.263,1.263,0,0,1,1.294,1.269m6.624-3.6a3.874,3.874,0,0,1-3.172-1.98c-.609,1.523-2.183,1.98-3.756,1.98H50.292v-.939h1.167c1.345,0,1.9-.888,1.9-2.36v-6.37c0-.558-.1-.66-1.117-1.015l3.35-.634v6.471c0,1.6,1.066,3.908,2.842,3.908H59.86v.939Zm-2.969,3.6a1.294,1.294,0,1,1-2.589,0,1.295,1.295,0,0,1,2.589,0" transform="translate(-36.41 -583.687)" fill="#1a7279"/>
                <path id="Path_17004" data-name="Path 17004" d="M86.309,601.748h4.467a5.681,5.681,0,0,1-2.487-4.847,5.832,5.832,0,0,1,5.913-5.812c1.9,0,3.654,1.167,3.654,2.868a1.446,1.446,0,0,1-1.472,1.5,1.294,1.294,0,0,1-1.447-1.269,1.051,1.051,0,0,1,1.193-1.117,3.4,3.4,0,0,1,.507.051,2.416,2.416,0,0,0-2.36-1.218c-2.259,0-3.451,1.6-3.451,4.72,0,2.944,1.091,5.127,3.6,5.127a3.738,3.738,0,0,0,2.589-.914l.635.431a4.99,4.99,0,0,1-3.68,1.421H86.309Z" transform="translate(-63.316 -582.917)" fill="#1a7279"/>
                <path id="Path_17005" data-name="Path 17005" d="M139.007,575.794c0-.609-.381-.838-1.827-.838v-.838l4.06-.177v13.1c0,1.345.609,2.055,1.675,2.132h1.269v.939h-1.421c-2.157,0-3.756-.761-3.756-3.172Z" transform="translate(-100.635 -570.338)" fill="#1a7279"/>
                <path id="Path_17006" data-name="Path 17006" d="M170.309,589.059a3.874,3.874,0,0,1-3.172-1.979c-.609,1.523-2.183,1.979-3.756,1.979h-1.624v-.939h1.167c1.345,0,1.9-.888,1.9-2.36v-6.37c0-.558-.1-.66-1.117-1.015l3.35-.635v6.472c0,1.6,1.066,3.908,2.842,3.908h.711v.939Zm-3.477-15.253a1.284,1.284,0,0,1-1.294,1.294,1.268,1.268,0,0,1-1.294-1.294,1.253,1.253,0,0,1,1.294-1.294,1.268,1.268,0,0,1,1.294,1.294" transform="translate(-118.665 -569.289)" fill="#1a7279"/>
                <path id="Path_17007" data-name="Path 17007" d="M193.289,604.544h.584c1.4,0,1.98-.939,1.98-2.437v-4.441c0-.558,0-.66-1.142-1.041l3.35-.609v4.619c1.98-3.451,4.644-5.736,6.218-5.736,3.858,0,6.192,3.756,6.446,9.644h3.3v.939H193.289Zm15.075,0c-.051-5.736-1.929-8.832-3.985-8.832-2.69,0-6.167,6.4-6.649,8.832Z" transform="translate(-141.797 -585.713)" fill="#1a7279"/>
                <path id="Path_17008" data-name="Path 17008" d="M269.215,589.029h.558c1.421,0,2-.939,2-2.437V575.5c0-.584,0-.761-1.117-1.117l3.35-.634v12.918c0,2.132-1.548,3.3-3.705,3.3h-1.091Z" transform="translate(-197.497 -570.198)" fill="#1a7279"/>
                <path id="Path_17009" data-name="Path 17009" d="M296.885,575.5c0-.584-.025-.761-1.091-1.117l3.324-.634v16.217h-2.233Z" transform="translate(-216.995 -570.199)" fill="#1a7279"/>
                <path id="Path_17010" data-name="Path 17010" d="M333.8,593.914c0-.609-.025-.787-1.142-1.142l3.375-.634v6.32c0,4.543-.431,9.568-6.116,9.568-.33,0-.888-.025-1.827-.1,3.68-.99,5.507-2.792,5.71-7.563Z" transform="translate(-240.685 -583.686)" fill="#1a7279"/>
                <path id="Path_17011" data-name="Path 17011" d="M368.4,575.794c0-.609-.381-.838-1.827-.838v-.838l4.06-.177v13.1c0,1.345.609,2.055,1.675,2.132h1.269v.939h-1.421c-2.157,0-3.756-.761-3.756-3.172Z" transform="translate(-268.919 -570.338)" fill="#1a7279"/>
                <path id="Path_17012" data-name="Path 17012" d="M408.63,604.544v.939H391.246v-.939H402.87c-.051-5.736-2.056-8.755-4.111-8.755-2.259,0-3.731,2.233-4.517,4.847l-1.066-1.9a2.365,2.365,0,0,0,1.041-.99,5.038,5.038,0,0,1,4.619-2.843c4.035,0,6.5,4.949,6.548,9.644Zm-8.426,4.543a1.32,1.32,0,1,1-1.32-1.294,1.31,1.31,0,0,1,1.32,1.294" transform="translate(-287.019 -585.713)" fill="#1a7279"/>
                <path id="Path_17013" data-name="Path 17013" d="M454.788,582.6h.584c1.4,0,1.98-.939,1.98-2.436v-4.441c0-.558,0-.66-1.142-1.04l3.35-.609V579.2c.177,1.523,1.117,3.477,2.69,3.477,1.447,0,2.03-.939,2.03-2.436v-5.457c0-.533-.025-.634-1.142-1.015l3.375-.634v5.558c0,1.6.99,3.984,2.741,3.984,1.421,0,2-.939,2-2.436V574c0-.609,0-.787-1.091-1.142l3.325-.634v8.02c0,2.132-1.548,3.3-3.705,3.3a3.7,3.7,0,0,1-3.452-2.157c-.33,1.218-1.929,2.157-3.5,2.157a3.906,3.906,0,0,1-3.528-1.751,3.529,3.529,0,0,1-3.426,1.751h-1.091Zm10-13.78a1.16,1.16,0,0,1-1.193,1.193,1.139,1.139,0,0,1-1.167-1.193,1.119,1.119,0,0,1,1.167-1.167,1.14,1.14,0,0,1,1.193,1.167m1.777-2.741a1.068,1.068,0,0,1-1.091,1.091,1.091,1.091,0,1,1,0-2.182,1.068,1.068,0,0,1,1.091,1.091m2,2.741a1.154,1.154,0,0,1-1.167,1.193,1.175,1.175,0,0,1-1.193-1.193,1.155,1.155,0,0,1,1.193-1.167,1.133,1.133,0,0,1,1.167,1.167" transform="translate(-333.633 -563.769)" fill="#1a7279"/>
                <path id="Path_17014" data-name="Path 17014" d="M533.1,564.83l.254-.685h1.193a2.21,2.21,0,0,1-1.015-1.726,1.938,1.938,0,0,1,2.031-2,1.431,1.431,0,0,1,1.573,1.32c0,.533-.3.685-.736.685-.406,0-.761-.2-.761-.558a.726.726,0,0,1,.355-.558.579.579,0,0,0-.482-.254c-.482,0-.736.558-.736,1.117,0,.736.533,1.979,1.573,1.979h1.117v.685Zm4.391,1.472-3.35.635c1.091.381,1.117.584,1.117,1.142v11.167h-1.421l-.609.939h6.269c1.6,0,3.147-.457,3.756-1.979a3.909,3.909,0,0,0,3.173,1.979H547v-.939h-.99c-1.776,0-2.868-2.31-2.868-3.908v-11.42l-3.325.635c1.015.355,1.117.457,1.117,1.015v11.319c0,1.472-.558,2.36-1.9,2.36h-1.548Z" transform="translate(-391.08 -560.414)" fill="#1a7279"/>
                <path id="Path_17015" data-name="Path 17015" d="M583.49,589.029h.558c1.421,0,2-.939,2-2.437V575.5c0-.584,0-.761-1.117-1.117l3.35-.634v12.918c0,2.132-1.548,3.3-3.705,3.3H583.49Z" transform="translate(-428.049 -570.198)" fill="#1a7279"/>
                <path id="Path_17016" data-name="Path 17016" d="M637.728,601.128c-.761-1.827-1.5-3.4-1.954-4.467-.178-.381-.3-.685-.432-.939-1.04-2.284-1.5-2.766-2.233-2.766-.406,0-.558.356-.558.762a3.427,3.427,0,0,0,.279,1.142c.127.33.2.558.381.939.381.863,1.091,2.614,3.122,7.588h-9.416c-2.157,0-3.781-.761-3.781-3.172v-4.67c0-.609-.381-.812-1.828-.812v-.863l4.061-.178v6.624c0,1.345.609,2.056,1.7,2.132h6.649c-1.294-3.578-3.375-7.03-3.375-8.958a1.31,1.31,0,0,1,1.447-1.447h.888c1.472,0,2.563.3,3.6,2.792.3.736.914,2.081,1.447,3.375.761,1.726,1.4,4.111,3.325,4.238h1.091v.939h-.609a3.685,3.685,0,0,1-3.807-2.259m-8.121,5.862a1.282,1.282,0,0,1-2.563,0,1.282,1.282,0,1,1,2.563,0m3.68,0a1.278,1.278,0,0,1-1.294,1.269,1.282,1.282,0,1,1,1.294-1.269" transform="translate(-455.792 -583.616)" fill="#1a7279"/>
                <rect id="Rectangle_6747" data-name="Rectangle 6747" width="3.985" height="0.939" transform="translate(185.895 18.831)" fill="#1a7279"/>
                <rect id="Rectangle_6748" data-name="Rectangle 6748" width="3.985" height="0.939" transform="translate(189.422 18.831)" fill="#1a7279"/>
                <path id="Path_17017" data-name="Path 17017" d="M724.289,602.517h.584c1.4,0,1.98-.939,1.98-2.436v-4.441c0-.558,0-.66-1.142-1.041l3.35-.609v5.126c.178,1.523,1.117,3.477,2.69,3.477,1.447,0,2.03-.939,2.03-2.437V594.7c0-.533-.025-.635-1.142-1.015l3.376-.634v5.558c0,1.6.99,3.985,2.741,3.985,1.421,0,2-.939,2-2.437v-6.243c0-.609,0-.787-1.091-1.142l3.325-.634v8.02c0,2.132-1.548,3.3-3.705,3.3a3.7,3.7,0,0,1-3.451-2.157c-.33,1.218-1.929,2.157-3.5,2.157a3.906,3.906,0,0,1-3.528-1.751,3.528,3.528,0,0,1-3.426,1.751h-1.091Z" transform="translate(-531.339 -583.686)" fill="#1a7279"/>
                <path id="Path_17018" data-name="Path 17018" d="M800.209,593.914c0-.609-.025-.787-1.142-1.142l3.375-.634v6.32a23.717,23.717,0,0,1-.279,4.06h3.705v.939h-3.883c-.609,2.589-2.107,4.568-5.66,4.568-.33,0-.888-.025-1.827-.1,3.68-.99,5.507-2.792,5.71-7.563Z" transform="translate(-582.845 -583.686)" fill="#1a7279"/>
                <path id="Path_17019" data-name="Path 17019" d="M835.367,589.029h.558c1.421,0,2-.939,2-2.437V575.5c0-.584,0-.761-1.117-1.117l3.35-.634v12.918c0,2.132-1.548,3.3-3.705,3.3h-1.091Z" transform="translate(-612.826 -570.198)" fill="#1a7279"/>
                <path id="Path_17020" data-name="Path 17020" d="M863.037,575.5c0-.584-.025-.761-1.091-1.117l3.324-.634v16.217h-2.233Z" transform="translate(-632.325 -570.199)" fill="#1a7279"/>
            </g>
            </g>
            </svg>
        </div>
        </>
    )
}

export default Logo;