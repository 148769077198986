import React, { useState } from "react";
import Header from "./Header";
import InvoiceSending from "./InvoiceSending";
import { post } from "./imporatnt_functions";

const isChild = (element,parent)=>{
    if(element === document.body){
        return false;
    }
    if(element === parent){
        return true
    }
    return isChild(element.parentElement,parent);
}

const Invoice = ()=>{
    
    const url = "https://rassi.altarek.tech/api/";

    let [mod,setMod] = useState(false)
    let [results,setResults] = useState([]);

    function printBill(){
        const content = document.getElementById("invoiceSendingForm");
        // console.log(content)
        let data = new FormData(content);
        data = Object.fromEntries(data.entries());
        // console.log(data)
        let contentToPrint = `
        <html>
            <head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <style media="print">
                * {
                    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
                    color-adjust: exact !important;  /*Firefox*/
                }
                @page {
                    size: A3 portrait;
                  }
            </style>
            </head>
            <div style="
                direction:rtl;
                display:flex;
                flex-direction: column;
                font-size: 20px;
                position: relative;
                top: 0px;
            ">
            <header style="text-align: center;font-size: 3.5vw; font-weight: bold; width: fit-content; position: relative; right: 50%; transform: translateX(50%);
             border: 2px dashed #000; border-radius: 30px; padding: 0.5vw 1vw; margin: -10px 0xp 0px 0px;">بوليصة شحنة عميل</header>

            <label style="font-size: 3.2vw; font-weight: 600; margin: 1vw;">بيانات المستلم :</label>
            <div style="display: flex; flex-direction: column; justify-content: center; gap: 15px; border: 1px solid #000; padding: 20px; border-radius: 20px; height: 20vw; font-size: 3.1vw; font-weight: 600;">
                <div style="display: flex; flex-direction: row; justify-contnet: center; align-items: center;">
                    <div style="flex: 20%;">اسم المستلم:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">${data.receiver_name}</div>
                    <div style="flex: 25%; text-align: left;">The recipient</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-contnet: space-between; align-items: center;">
                    <div style="flex: 20%;">الوجهة:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">${data.address}</div>
                    <div style="flex: 25%; text-align: left;">City</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-contnet: space-between; align-items: center;">
                    <div style="flex: 20%;">جوال المستلم:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">${data.receiver_phone}</div>
                    <div style="flex: 25%; text-align: left;">mobile phone</div>
                </div>
            </div>

            <label style="font-size: 3.2vw; font-weight: 600; margin: 1vw 0 0.5vw 0;">بيانات المرسل :</label>
            <div style="display: flex; flex-direction: column; justify-content: center; gap: 5px; border: 1px solid #000; padding: 20px; border-radius: 20px; height: 20vw; font-size: 3.1vw; font-weight: 600;">
                <div style="display: flex; flex-direction: row; justify-contnet: center; align-items: center;">
                    <div style="flex: 20%;">اسم المرسل:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">سليمان عبد الله الرسي للتجارة</div>
                    <div style="flex: 25%; text-align: left;">The recipient</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-contnet: space-between; align-items: center;">
                    <div style="flex: 20%;">عنوان المرسل:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">الرياض_حي الفيصيلية_شارع أسد السنة-7002465974</div>
                    <div style="flex: 25%; text-align: left;">City</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-contnet: space-between; align-items: center;">
                    <div style="flex: 20%;">جوال المرسل:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">${data.sender_phone}</div>
                    <div style="flex: 25%; text-align: left;">mobile phone</div>
                </div>
            </div>


            <div style="height=0px; border-top: .2vw solid #000; width: 100%; margin: 1.7vw 0px 1.7vw 0px;"></div>


            <header style="text-align: center;font-size: 3.5vw; font-weight: bold; width: fit-content; position: relative; right: 50%; transform: translateX(50%);
             border: 2px dashed #000; border-radius: 30px; padding: 0.5vw 1vw;">بوليصة شحنة عميل</header>

            <label style="font-size: 3.2vw; font-weight: 600; margin: 1vw;">بيانات المستلم :</label>
            <div style="display: flex; flex-direction: column; justify-content: center; gap: 15px; border: 1px solid #000; padding: 20px; border-radius: 20px; height: 20vw; font-size: 3.1vw; font-weight: 600;">
                <div style="display: flex; flex-direction: row; justify-contnet: center; align-items: center;">
                    <div style="flex: 20%;">اسم المستلم:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">${data.receiver_name}</div>
                    <div style="flex: 25%; text-align: left;">The recipient</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-contnet: space-between; align-items: center;">
                    <div style="flex: 20%;">الوجهة:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">${data.address}</div>
                    <div style="flex: 25%; text-align: left;">City</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-contnet: space-between; align-items: center;">
                    <div style="flex: 20%;">جوال المستلم:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">${data.receiver_phone}</div>
                    <div style="flex: 25%; text-align: left;">mobile phone</div>
                </div>
            </div>
            

            <label style="font-size: 3.2vw; font-weight: 600; margin: 1vw 0 0.5vw 0;">بيانات المرسل :</label>
            <div style="display: flex; flex-direction: column; justify-content: center; gap: 5px; border: 1px solid #000; padding: 20px; border-radius: 20px; height: 20vw; font-size: 3.1vw; font-weight: 600;">
                <div style="display: flex; flex-direction: row; justify-contnet: center; align-items: center;">
                    <div style="flex: 20%;">اسم المرسل:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">سليمان عبد الله الرسي للتجارة</div>
                    <div style="flex: 25%; text-align: left;">The recipient</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-contnet: space-between; align-items: center;">
                    <div style="flex: 20%;">عنوان المرسل:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">الرياض_حي الفيصيلية_شارع أسد السنة-7002465974</div>
                    <div style="flex: 25%; text-align: left;">City</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-contnet: space-between; align-items: center;">
                    <div style="flex: 20%;">جوال المرسل:</div>
                    <div style="flex: 70%; background-color: #e6e6e6; text-align: center; box-sizing: border-box; padding: 4px 5px;">${data.sender_phone}</div>
                    <div style="flex: 25%; text-align: left;">mobile phone</div>
                </div>
            </div>

                
        </html>
        `;
        // console.log(contentToPrint);
        const print = document.getElementById("frameContentToPrint").contentWindow;
        print.document.open()
        print.document.write(contentToPrint)
        print.document.close();
        print.focus();
        print.print();
    }

    const printInvoice = (e)=>{
        const content = document.getElementById("invoiceSendingForm");
        // console.log(content)
        let data = new FormData(content);
        data = Object.fromEntries(data.entries());
        // console.log(data)
        let contentToPrint = `
        <html>
            <head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <style media="print">
                @page{ size: landscape; }
                * {
                    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
                    color-adjust: exact !important;  /*Firefox*/
                }
            </style>
            </head>
            
            <div style=" direction: rtl; font-family: Almarai; font-weight: 600; position: fixed; font-size: 0.8vw; top: 43%; left: 45%; transform: translate(-50%,-50%);
            display: flex; flex-direction: column; border: 0.2vw dashed #666; border-radius: 20px; width: fit-content; padding: 10px 15px; gap: 5px;">
                <div style="display: flex; flex-direction: row; justify-content: left; align-items: center;">
                    <div style="text-align: left;">العميل / Customer&nbsp;:</div>
                    <div style="background-color: #e6e6e6; padding: 3px; box-sizing: border-box; width: 200px; text-align: center; font-size: 1vw; ">${data.client_name}</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: left; align-items: center;">
                    <div style="text-align: none; width: fit-content;">المستلم / The recipient&nbsp;:</div>
                    <div style="background-color: #e6e6e6; padding: 3px; box-sizing: border-box; text-align: center; width: 200px; font-size: 1vw; ">${data.receiver_name}</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: left; align-items: center;">
                    <div style="text-align: left;">جوال / Phone&nbsp;:</div>
                    <div style="background-color: #e6e6e6; padding: 3px; box-sizing: border-box; width: 200px; text-align: center; font-size: 1vw; ">${data.receiver_phone}</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: left; align-items: center;">
                    <div style="text-align: left;">العنوان / Address&nbsp;:</div>
                    <div style="background-color: #e6e6e6; padding: 3px; box-sizing: border-box; width: 200px; text-align: center; font-size: 1vw; ">${data.address}</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: left; align-items: center;">
                    <div style="width: fit-content;">الدولة / Country&nbsp;:</div>
                    <div style="background-color: #e6e6e6; padding: 3px; box-sizing: border-box; width: 200px; text-align: center;">المملكة العربية السعودية</div>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: left; align-items: center;">
                    <div style="box-sizing: border-box; text-align: none;">وقت الشحنة / Time&nbsp;:</div>
                    <div style="display: flex; flex-direction: row; width: 200px;  align-items: center;">
                        <div style="background-color: #e6e6e6; padding: 3px; box-sizing: border-box; width: 60%; text-align: center; font-size: 0.8vw; font-weight: 600; font-size: 1vw; ">${data.date}</div>
                        <div style="box-sizing: border-box; width: 20%; text-align: center;">ملاحظة&nbsp;:</div>
                        <div style="background-color: #e6e6e6; padding: 3px; box-sizing: border-box; text-align: center; width:20%; font-size: 1vw; ">${data.note?data.note:""}</div>
                    </div>
                </div>
            </div>
        </html>
        `;
        // console.log(contentToPrint);
        const print = document.getElementById("frameContentToPrint").contentWindow;
        print.document.open()
        print.document.write(contentToPrint)
        print.document.close();
        print.focus();
        print.print();
    }

    const openMsgWindow = (e)=>{
        const msgWindow = document.getElementById("messageWindow");
        const background = document.getElementById("bg");
        msgWindow.style.display = "flex";
        background.style.display = "block";
        const closeMsgBox = (e)=>{
            if(!isChild(e.target,msgWindow)){
                msgWindow.style.display = "none";
                background.style.display = "none";
                document.removeEventListener("click",closeMsgBox);
            }
        }
        setTimeout(()=>{
            document.addEventListener("click",closeMsgBox);
        },10)
    }

    const save = (e)=>{
        if(!mod){
            const form = document.getElementById("invoiceSendingForm")
            let data = new FormData(form);
            // console.log(Array.from(data));
            const bg = document.getElementById("bg");
            const loadingDiv = document.getElementById("loading");
            bg.style.display = "block";
            loadingDiv.classList.toggle("loader");
            loadingDiv.style.display = "block";
            post(`${url}createDispatch`,data).then(res=>{
                // console.log(res.status);
                loadingDiv.classList.toggle("loader")
                loadingDiv.classList.toggle("loading")
                if(res.status === "false"){
                    loadingDiv.innerText = "حدث خطأ";
                }
                else loadingDiv.innerText = "تم";
                setTimeout(()=>{
                    bg.style.display = "none";
                    loadingDiv.style.display = "none";
                    loadingDiv.classList.toggle("loading")
                    loadingDiv.innerText = "";
                },1000);
            }).catch(error=>{
                loadingDiv.innerText = "حدث خطأ.";
                setTimeout(()=>{
                    bg.style.display = "none";
                    loadingDiv.style.display = "none";
                    loadingDiv.classList.toggle("loading")
                    loadingDiv.innerText = "";
                },1000);
                return error;
            })
        }else{
            const form = document.getElementById("invoiceSendingForm");
            let data = new FormData(form);
            // console.log(Array.from(data))
            const bg = document.getElementById("bg");
            const loadingDiv = document.getElementById("loading");
            bg.style.display = "block";
            loadingDiv.classList.toggle("loader");
            loadingDiv.style.display = "block";
            post(`${url}updateDispatch`,data).then(res=>{
                // console.log(res);
                loadingDiv.classList.toggle("loader")
                loadingDiv.classList.toggle("loading")
                if(res.status === "false"){
                    loadingDiv.innerText = "حدث خطأ";
                }
                else loadingDiv.innerText = "تم";
                setTimeout(()=>{
                    bg.style.display = "none";
                    loadingDiv.style.display = "none";
                    loadingDiv.classList.toggle("loading")
                    loadingDiv.innerText = "";
                },1000);
            }).catch(error=>{
                loadingDiv.innerText = "حدث خطأ";
                setTimeout(()=>{
                    bg.style.display = "none";
                    loadingDiv.style.display = "none";
                    loadingDiv.classList.toggle("loading")
                    loadingDiv.innerText = "";
                },1000);
                return error;
            })
        }
    }

    const search = ()=>{
        const search = document.getElementById("bill-search-bar").value;
        // let data = new FormData(search);
        let data = new FormData()
        if(isNaN(+search))
            data.append("text",search)
        else 
            data.append("receiver_phone",search)
        console.log(Array.from(data))
        const resultsDiv = document.getElementById("results");
        const loading = document.getElementById("res-loading");
        loading.style.display = "block";
        loading.innerHTML = "جاري التحميل";
        post(`${url}searchDispatches`,data).then(results=>{
            // console.log(results.data.dispatches);
            setResults(results.data.dispatches);
            if(results.data.dispatches.length === 0){
                loading.innerHTML = "لم يتم العثور على طلب";
            }else{
                loading.style.display = "none";
            }
        }).catch(error=>{
            return error
        })
    }

    const update = (e)=>{
        setMod(true);
        Array.from(document.getElementsByTagName("input")).forEach((element)=>{
            if(element.id === "bill-search-bar" || element.id === "date" || element.id === "id") return;
            element.style.pointerEvents = "auto";
            element.style.backgroundColor = "#fff";
        })
    }

    const sendMessage = (e) =>{
        const message = document.getElementById("message-text-field").value;
        post("https://www.msegat.com/gw/sendsms.php",message).then((res)=>{
            // console.log(res);
        }).catch(e=>{
            return e
        })
    }
    
    return (
        <>
        <div className="invoice-main-container">
            <Header/>
            <div className="invoice-head head">
                إرسالية فاتورة
            </div>
            <InvoiceSending search={search} results={results}/>
            <div className="invoice-buttons">
                <button onClick={e=>window.location.reload()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.136" height="20.136" viewBox="0 0 20.136 20.136">
                        <path id="Path_17022" data-name="Path 17022" d="M360.508,151.44a1.165,1.165,0,0,1,1.128,1.128,1.108,1.108,0,0,1-.341.812,1.067,1.067,0,0,1-.787.315H352.7v7.813a1.108,1.108,0,0,1-.341.812,1.061,1.061,0,0,1-.787.315,1.081,1.081,0,0,1-1.128-1.128V153.7h-7.813a1.081,1.081,0,0,1-1.128-1.128,1.062,1.062,0,0,1,.315-.787,1.109,1.109,0,0,1,.812-.341h7.813v-7.813a1.068,1.068,0,0,1,.315-.787,1.108,1.108,0,0,1,.812-.341,1.165,1.165,0,0,1,1.128,1.128v7.813Z" transform="translate(-341.5 -142.5)" fill="#fff"/>
                    </svg>
                    <span>جديد</span>     
                </button>
                <button onClick={save}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.819" height="28.819" viewBox="0 0 28.819 28.819">
                        <path id="Path_17025" data-name="Path 17025" d="M326.2,745h19.213l6.4,6.4v19.213a3.212,3.212,0,0,1-3.2,3.2H326.2a3.2,3.2,0,0,1-3.2-3.2V748.2A3.2,3.2,0,0,1,326.2,745Zm22.415,25.617V752.733l-4.531-4.531H326.2v22.415Zm-11.207-11.207a4.8,4.8,0,1,0,4.8,4.8A4.8,4.8,0,0,0,337.409,759.409Zm4.8-9.606H327.8v6.4h14.409Z" transform="translate(-323 -745)" fill="#fff" fill-rule="evenodd"/>
                    </svg>
                    <span>حفظ</span>     
                </button>
                <button onClick={update}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.046" height="26.971" viewBox="0 0 29.046 26.971">
                        <g id="edit" transform="translate(-12 -12)">
                            <path id="Path_17032" data-name="Path 17032" d="M12,156H41.046v2.075H12Z" transform="translate(0 -119.104)" fill="#fff"/>
                            <path id="Path_17033" data-name="Path 17033" d="M46.2,19.261a2.006,2.006,0,0,0,0-2.9l-3.734-3.734a2.006,2.006,0,0,0-2.9,0L24,28.183v6.639h6.639Zm-5.187-5.187,3.734,3.734-3.112,3.112L37.9,17.187ZM26.075,32.747V29.013L36.448,18.639l3.734,3.734L29.809,32.747Z" transform="translate(-9.925)" fill="#fff"/>
                        </g>
                    </svg>
                    <span>تعديل</span>
                </button>
                <button onClick={printBill}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.542" height="29.542" viewBox="0 0 29.542 29.542">
                        <g id="Group_13450" data-name="Group 13450" transform="translate(1732 -2616.229)">
                            <path id="Path_17034" data-name="Path 17034" d="M30.944,12.979V6.08H10.757v6.9A4.93,4.93,0,0,0,6.08,17.9V29.713h4.185V27.744H8.049V17.9A2.958,2.958,0,0,1,11,14.943H30.7A2.958,2.958,0,0,1,33.652,17.9v9.847H30.944v1.969h4.677V17.9a4.93,4.93,0,0,0-4.677-4.917Zm-1.969-.006H12.727V8.049H28.975Z" transform="translate(-1738.08 2610.149)" fill="#fff"/>
                            <path id="Path_17036" data-name="Path 17036" d="M31.342,100.32H28.88v1.969h2.462V114.6h16.74V102.289h2.462V100.32Zm14.771,12.309h-12.8v-10.34h12.8Z" transform="translate(-1757.187 2531.172)" fill="#fff"/>
                            <path id="Path_17037" data-name="Path 17037" d="M150.48,76h1.969v1.969H150.48Z" transform="translate(-1860.093 2552.046)" fill="#fff"/>
                        </g>
                    </svg>
                    <span>طباعة بوليصة</span>
                </button>
                <button onClick={printInvoice}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.542" height="29.542" viewBox="0 0 29.542 29.542">
                        <g id="Group_13450" data-name="Group 13450" transform="translate(1732 -2616.229)">
                            <path id="Path_17034" data-name="Path 17034" d="M30.944,12.979V6.08H10.757v6.9A4.93,4.93,0,0,0,6.08,17.9V29.713h4.185V27.744H8.049V17.9A2.958,2.958,0,0,1,11,14.943H30.7A2.958,2.958,0,0,1,33.652,17.9v9.847H30.944v1.969h4.677V17.9a4.93,4.93,0,0,0-4.677-4.917Zm-1.969-.006H12.727V8.049H28.975Z" transform="translate(-1738.08 2610.149)" fill="#fff"/>
                            <path id="Path_17036" data-name="Path 17036" d="M31.342,100.32H28.88v1.969h2.462V114.6h16.74V102.289h2.462V100.32Zm14.771,12.309h-12.8v-10.34h12.8Z" transform="translate(-1757.187 2531.172)" fill="#fff"/>
                            <path id="Path_17037" data-name="Path 17037" d="M150.48,76h1.969v1.969H150.48Z" transform="translate(-1860.093 2552.046)" fill="#fff"/>
                        </g>
                    </svg>
                    <span>طباعة فاتورة</span>
                </button>
                <button onClick={openMsgWindow} id="message" className="message">
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.046" height="31.328" viewBox="0 0 29.046 31.328">
                        <g id="bx-message-alt-detail" transform="translate(264 -16)">
                            <g id="Group_13448" data-name="Group 13448">
                            <g id="Group_13449" data-name="Group 13449">
                                <path id="Path_17030" data-name="Path 17030" d="M27.227,16A3.231,3.231,0,0,0,24,19.227V38.591a3.231,3.231,0,0,0,3.227,3.227h5.787l5.509,5.509,5.509-5.509h5.787a3.231,3.231,0,0,0,3.227-3.227V19.227A3.231,3.231,0,0,0,49.819,16ZM49.819,38.591H42.7l-4.173,4.173L34.35,38.591H27.227V19.227H49.819Z" transform="translate(-288)" fill="#fff"/>
                                <path id="Path_17031" data-name="Path 17031" d="M56,56H72.137v3.227H56Zm0,6.455H67.3v3.227H56Z" transform="translate(-313.545 -31.932)" fill="#fff"/>
                            </g>
                            </g>
                        </g>
                    </svg>
                    <span>رسالة نصية</span>
                </button>
            </div>
            <div className="msg" id="messageWindow">
                <header>رسالة نصية</header>
                <textarea id="message-text-field" className="msg-text-field"></textarea>
                <div>
                    <button id="send-message" className="send-message" onClick = {sendMessage}>ارسال</button>
                    <button id="save-message" className="save-message">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.819" height="28.819" viewBox="0 0 28.819 28.819">
                            <path id="Path_17025" data-name="Path 17025" d="M326.2,745h19.213l6.4,6.4v19.213a3.212,3.212,0,0,1-3.2,3.2H326.2a3.2,3.2,0,0,1-3.2-3.2V748.2A3.2,3.2,0,0,1,326.2,745Zm22.415,25.617V752.733l-4.531-4.531H326.2v22.415Zm-11.207-11.207a4.8,4.8,0,1,0,4.8,4.8A4.8,4.8,0,0,0,337.409,759.409Zm4.8-9.606H327.8v6.4h14.409Z" transform="translate(-323 -745)" fill="#fff" fill-rule="evenodd"/>
                        </svg>
                        حفظ
                    </button>
                </div>
            </div>
            <iframe id="frameContentToPrint" style={{height:"0px",width:"0px",display:"none"}}></iframe>
        </div>
        </>
    )
}

export default Invoice;