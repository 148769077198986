import React from "react";

const Result = (props)=>{
    return (
        <>
            <div className="result" onClick={e=>props.selectResult(props.result)}>
                <div className="result-client-name">
                    <span>اسم العميل:&nbsp;</span>
                    <span>{props.result.client_name}</span>
                </div>
                <div className="result-receiver-name">
                    <span>اسم المستلم:&nbsp;</span>
                    <span>{props.result.receiver_name}</span>
                </div>
            </div>
        </>
    )
}

export default Result;