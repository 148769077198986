import React, { useEffect } from "react";
import Logo from "./Company'sLogo";
import { Link } from "react-router-dom";

const Header = (props)=>{

    const isChild = (element,parent)=>{
        if(element === document.body){
            return false;
        }
        if(element === parent){
            return true
        }
        return isChild(element.parentElement,parent);
    }

    return (
        <>
        <div className="main-header">
            <div className="logo">
                <Logo/>
            </div>
        </div>
        <div id="bg" className="bg"></div>
        <div className="loading-area" id="loading">
            {/* جاري التحميل */}
        </div>
        </>
    )
}

export default Header;