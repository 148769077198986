import React, { useState } from "react";
import Result from "./Result";
import { useEffect } from "react";

const InvoiceSending = (props)=>{

    let [results,setResults] = useState([])
    let [result,setResult] = useState("");
    let [date,setDate] = useState();

    useEffect(()=>{
        const resultsDiv = document.getElementById("results");
        const isChild = (ele,parent)=>{
            if(ele === parent) return true;
            if(ele === document.body) return false;
            return isChild(ele.parentElement,parent);
        }
        const hideResults = (e)=>{
            // if(!isChild(e.target,resultsDiv)){
                // }
            resultsDiv.style.display = "none";
            document.removeEventListener("click",hideResults)
        }
        document.addEventListener("click",hideResults)
    })

    useEffect(()=>{
        const resultsDiv = document.getElementById("results");
        const searchBar = document.getElementById("bill-search-bar");
        const searchBarRect = searchBar.getBoundingClientRect();
        if(searchBar.value === ""){
            resultsDiv.style.display = "none";
        }else{
            resultsDiv.style.display = "flex";
            // console.log(searchBarRect.bottom)
            resultsDiv.style.width = `${searchBarRect.width}px`;
            setResults(props.results);
        }
    },[props.results])

    useEffect(()=>{
        const date = new Date();
        // console.log(`${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`)
        // document.getElementById("date").defaultValue = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
        setDate(`${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`);
    },[])

    const selectResult = (result)=>{
        setResult(result)
        setDate(result.date)
        Array.from(document.getElementsByTagName("input")).forEach(element => {
            if(element.id === "bill-search-bar"){
                return;
            }
            console.log(element)
            element.style.backgroundColor = "#f3f3f3";
            element.style.pointerEvents = "none";
        });
    }

    return(
        <>
        <div id="invoiceSendingMain" className="invoice-sending-main">
        <form className="bill-search" id="bill-search">
            <input type="text" placeholder="إبحث برقم الجوال أو اسم المستلم" id="bill-search-bar" onClick={e=>props.search()} onChange={e=>props.search()}/>
            <button className="search-orders-btn" onClick={e=>e.preventDefault()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28.669" height="28.669" viewBox="0 0 28.669 28.669">
                    <path id="Path_9200" data-name="Path 9200" d="M30.088,27.646,23.72,21.293a12.107,12.107,0,1,0-2.427,2.427l6.354,6.368a1.728,1.728,0,0,0,2.441,0h0a1.728,1.728,0,0,0,0-2.441ZM14.083,22.692a8.567,8.567,0,1,1,8.567-8.567,8.567,8.567,0,0,1-8.567,8.567Z" transform="translate(-1.924 -1.924)" fill="#707070"/>
                </svg>
            </button>
        </form>
        <div className="results" id="results">
            <div className="loading-res" id="res-loading" style={{display:"none"}}>جارى التحميل</div>
                {results.map(result=>
                    (
                        <>
                            <Result key={result.id} result={result} selectResult={selectResult}/>
                        </>
                    )
                )}
        </div>
        <form id="invoiceSendingForm" className="main-invoice">
            <div className="first-invoice-conatiner">
                <div className="right-bill-container">
                    <label htmlFor="id" style={{pointerEvents:"none"}}>رقم الشحنة</label>
                    <input type="text" id="id" name="id" style={{pointerEvents:"none",backgroundColor:"#f3f3f3"}} value={result.id}/>
                    <label htmlFor="zone">منطقة الشحن</label>
                    <input type="text" id="zone" name="zone" value={result.zone}/>
                    <label htmlFor="client_name">اسم الشركة</label>
                    <input type="text" id="client_name" name="client_name" value={result.client_name}/>
                    <label htmlFor="receiver_phone">رقم المستلم</label>
                    <input type="text" id="receiver_phone" name="receiver_phone" value={result.receiver_phone}/>
                </div>
                <div className="left-bill-container">
                    <label htmlFor="date">تاريخ الإرسالية</label>
                    <input type="text" id="date" name="date" style={{pointerEvents:"none",backgroundColor:"#f3f3f3"}} value={date}/>
                    <label htmlFor="address">عنوان الشحن</label>
                    <input type="text" id="address" name="address" value={result.address}/>
                    <label htmlFor="receiver_name">اسم المستلم</label>
                    <input type="text" id="receiver_name" name="receiver_name" value={result.receiver_name}/>
                    <label htmlFor="sender_phone">جوال المرسل</label>
                    <input type="text" id="sender_phone" name="sender_phone" value={result.sender_phone}/>
                </div>
            </div>
            <div className="secondary-div">
                <label className="title-label" htmlFor="">بوليصة</label>
                <div className="second-invoice-container">
                    <div className="right-bill-container">
                        <label htmlFor="driver_name">اسم السائق</label>
                        <input type="text" id="driver_name" name="driver_name" value={result.driver_name}/>
                        <label htmlFor="price">الإيجار</label>
                        <input type="text" id="price" name="price" value={result.price}/>
                    </div>
                    <div className="left-bill-container">
                        <label htmlFor="driver_phone">رقم السائق</label>
                        <input type="text" id="driver_phone" name="driver_phone" value={result.driver_phone}/>
                        <label htmlFor="note">ملاحظة</label>
                        <input type="text" id="note" name="note"/>
                    </div>
                </div>          
            </div>
            <div className="secondary-div">
                <label className="title-label" htmlFor="">فاتورة</label>
                <div className="third-invoice-container">
                    <div className="right-bill-container">
                        <label htmlFor="shipping_company">شركة الشحن</label>
                        <input type="text" id="shipping_company" name="shipping_company" defaultValue={result.shipping_company}/>
                    </div>
                    <div className="left-bill-container">
                        <label htmlFor="package_count">عدد الطرود</label>
                        <input type="text" id="package_count" name="package_count" defaultValue={result.package_count}/>
                    </div>
                </div>
            </div>
        </form>
        </div>
        </>
    )
}

export default InvoiceSending;